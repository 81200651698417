import { useQuery } from '@apollo/client';
import React from 'react';
import { Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import getOnlineTrainings from '../graphql/queries/getOnlineTrainings.graphql';
import Loader from './Loader';
import TrainingThumbnail from './TrainingThumbnail';

export default function OnlineTrainingsContent({ trainingType }) {
  const { data, loading } = useQuery(getOnlineTrainings);
  if (loading || !data) {
    return <Loader />;
  }
  const onlineTrainings = data.getOnlineTrainings;
  return (
    <Flex flexDirection={'row'} color={'text'} flexWrap={'wrap'}>
      {onlineTrainings ? (
        onlineTrainings.map((training) => (
          <TrainingThumbnail
            data={training}
            key={training._id}
            type={trainingType}
          />
        ))
      ) : (
        <Loader />
      )}
    </Flex>
  );
}

OnlineTrainingsContent.propTypes = {
  trainingType: PropTypes.string.isRequired,
};
