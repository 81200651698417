import React from 'react';
import Layout from '../components/Layout';
import TrainingsPage from '../components/TrainingsPage';
import OnlineTrainingsContent from '../components/OnlineTrainingsContent';

const OnlineTrainingsRoute = () => {
  return (
    <Layout>
      <TrainingsPage trainingType={'szkolenia-online'}>
        <OnlineTrainingsContent trainingType={'szkolenia-online'} />
      </TrainingsPage>
    </Layout>
  );
};

OnlineTrainingsRoute.propTypes = {};

OnlineTrainingsRoute.defaultProps = {};

export default OnlineTrainingsRoute;
